<template>
  <index/>
</template>

<script>
import index from '@/ittijs/IndexDefault';
import Model from './Model';

const imodel = new Model;

export default {
  
  components: {
    index,
  },

  provide: function () {
    return {
      imodel,
    };
  },

}
</script>